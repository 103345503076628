import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";


function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="w-full bg-[#012326] p-4 text-white h-20 flex justify-between items-center px-8 relative z-50">
      {/* Logo */}
      <Link to="/">
        <img
        src="/images/logo.png"
        alt="MindStudio Logo"
         className="h-10 object-cover"
      />
      </Link>

      {/* Menu Desktop */}
      <nav className="hidden md:flex space-x-6">
        <Link to="/about" className="hover:text-gray-400">Sobre Nós</Link>
        <Link to="/services" className="hover:text-gray-400">Serviços</Link>
        <Link to="/studio" className="hover:text-gray-400">Estúdio</Link>
        <Link to="/contact" className="hover:text-gray-400">Contato</Link>
      </nav>

      {/* Menu Mobile */}
      <div className="md:hidden">
        <button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>

      {isOpen && (
        <div className="absolute top-16 left-0 w-full bg-[#012326] p-6 flex flex-col items-center md:hidden ">
          <Link to="/about" className="py-2" onClick={() => setIsOpen(false)}>Sobre Nós</Link>
          <Link to="/services" className="py-2" onClick={() => setIsOpen(false)}>Serviços</Link>
          <Link to="/studio" className="py-2" onClick={() => setIsOpen(false)}>Estúdio</Link>
          <Link to="/contact" className="py-2" onClick={() => setIsOpen(false)}>Contato</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
