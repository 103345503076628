import React from "react";
import { FaDumbbell, FaRunning, FaLaptop, FaHome, FaClipboardList, FaChartLine } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Services() {
  const navigate = useNavigate();

  const services = [
    {
      title: "1- PERSONAL TRAINING",
      description:
        "Treine de forma correta e consistente, obtendo resultados mais rápidos, com o acompanhamento de um personal trainer.",
      icon: <FaDumbbell className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "1- PERFORMANCE DESPORTIVA",
      description:
        "Se é atleta e quer melhorar a sua performance em campo, não exite em contactar-nos. NÓS AJUDAMO-LO",
      icon: <FaRunning className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "2- TREINO ONLINE",
      description:
        "O MIND em qualquer lugar. Não deixe de treinar apenas porque não pode deslocar-se ao estúdio. O nosso acompanhamento personalizado permite-lhe realizar treinos online em qualquer parte do mundo.",
      icon: <FaLaptop className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "3- TREINO AO DOMICÍLIO",
      description:
        "Sem vontade de sair de casa, com preferência pelo conforto do seu lar. Nós temos a solução. Entre em contacto connosco.",
      icon: <FaHome className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "4- PLANOS DE TREINO AJUSTADOS",
      description:
        "Sem tempo para treinar? Nós adaptamo-nos a si. Com treinos inteiramente adaptados ao seu corpo e estilo de vida, tempo para treinar não vai faltar. O difícil vai ser parar.",
      icon: <FaClipboardList className="text-4xl text-[#BFAE99]" />,
    },
    {
      title: "5- AVALIAÇÕES FÍSICAS REGULARES",
      description: "Acompanhamento contínuo para garantir sua evolução.",
      icon: <FaChartLine className="text-4xl text-[#BFAE99]" />,
    },
  ];

  return (
    <section id="services" className="py-16 px-8 bg-[#012326]">
      <h2 className="text-3xl font-bold text-center mb-8 text-[#BFAE99]">
        Serviços
      </h2>

      {/* Grid de Serviços */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-[#01494d] text-white p-6 shadow-lg rounded-lg flex flex-col items-center justify-center text-center border border-[#BFAE99] hover:scale-105 transition-transform duration-300"
          >
            {/* Ícone */}
            <div className="mb-4">{service.icon}</div>
            {/* Título */}
            <h3 className="text-xl font-bold mb-2">{service.title}</h3>
            {/* Descrição Justificada */}
            <p className="text-justify">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Botão de Contato */}
      <div className="text-center mt-8">
        <button
          onClick={() => navigate("/contact")}
          className="px-6 py-3 bg-[#BFAE99] text-[#012326] font-bold rounded-lg hover:bg-[#d0b8a3] transition-colors duration-300 shadow-md"
        >
          Entre em contacto!
        </button>
      </div>

      {/* Caixa de Informação (Justificada) */}
      <div className="bg-white bg-opacity-95 text-[#012326] mt-12 p-6 md:p-8 rounded-lg shadow-lg max-w-5xl mx-auto text-center">
        <p className="text-lg text-justify">
          No MIND, todos os alunos terão o ginásio só para si durante a hora reservada e serão acompanhados por um personal trainer.
        </p>
        <p className="text-lg text-justify mt-4">
          Os treinos podem variar entre 50 a 60 minutos, variando o valor das aulas tendo em conta a frequência de treinos semanais escolhida pelo aluno.
        </p>
        <p className="text-lg text-justify mt-4">
          Temos 2 tipos de treinos: <strong>Treino Individual</strong> e <strong>Treino Duo</strong>.
        </p>
        <p className="text-lg text-justify mt-4">
          É do nosso interesse que os atletas atinjam os seus objetivos de forma eficaz e, por isso, são traçados planos de treino personalizados
          (adequado e adaptado às condições e objetivos de cada aluno) para cada aluno e serão realizadas avaliações físicas regulares.
        </p>
        <p className="text-lg text-justify mt-4">
          No nosso estúdio não existe qualquer tipo de contrato ou fidelização. Prezamos pela liberdade dos nossos alunos e queremos que eles se mantenham
          connosco enquanto fizer sentido nas suas vidas.
        </p>
      </div>
    </section>
  );
}

export default Services;
