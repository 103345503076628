import React from "react";

function About() {
  return (
    <section
      id="about"
      className="py-16 px-8 min-h-screen flex flex-col justify-center"
      style={{ backgroundColor: "#012326" }}
    >
      <h2 className="text-3xl font-bold text-center mb-8" style={{ color: "#BFAE99" }}>
        Sobre Nós
      </h2>
      <div className="flex flex-col md:flex-row items-stretch gap-8 max-w-6xl mx-auto">
        {/* Texto à Esquerda */}
        <div className="text-white flex items-center flex-1">
          <div className="space-y-6">
            <p className="text-justify">
              Nos dias de hoje, com a correria do quotidiano, pouca motivação sobra para ir ao ginásio e, mesmo quando vamos, não conseguimos tirar o melhor partido dessa ida.
              Sabendo disto, no MIND, a falta de tempo deixa de ser desculpa, pois pode treinar à hora que quiser, podendo encontrar um horário mais adequado à sua rotina,
              que lhe permitirá conciliar a sua vida pessoal com a vida profissional. Para além disto, poderá criar, com mais facilidade, uma rotina de treino tendo em vista a sua
              evolução a um ritmo mais seguro devido ao acompanhamento a que vai ser submetido.
            </p>
            <p className="text-justify">
              São inúmeras as razões que nos levam a procurar o treino personalizado. Saúde, estética, lazer, competição, mas o que nos faz voltar, são as sensações vividas durante a sessão de treino.
              A empatia, a companhia e a boa energia fazem com que os objectivos sejam mais facilmente alcançáveis. Foi exatamente isso que quisemos criar, um espaço perfeito, calmo e acolhedor com profissionais dinâmicos para vos receber.
            </p>
          </div>
        </div>

        {/* Imagem à Direita */}
        <div className="flex-1 flex items-center justify-center">
          <img
            src="/images/sobrenos.jpg"
            alt="Espaço MIND"
            className="rounded-lg shadow-lg w-3/4 max-w-sm"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
