import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";

function Slider() {
  const images = [
    "/images/slide1.jpg",
    "/images/slide2.jpg",
    "/images/slide3.jpg",
    "/images/slide4.jpg",
    "/images/slide5.jpg",
  ];

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      {/* Container do Slider */}
      <div className="w-full flex justify-center ">
        <Swiper
          effect={"coverflow"}
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
          spaceBetween={0}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 70,
            },
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="w-full md:pb-0 pb-8"
        >
          {images.map((img, index) => (
            <SwiperSlide key={index} className="flex justify-center items-center">
              <img src={img} alt={`Slide ${index + 1}`} className="w-auto object-contain" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Texto abaixo do Slider */}
      <div className="max-w-4xl mx-auto px-4 mt-4 mb-4 text-center">
        

        {/* Segunda Frase (Centralizada e Ajustada no Mobile) */}
        <p className="font-semibold text-xs md:text-lg text-center mt-2">
          NÃO SE COLOQUE EM SEGUNDO PLANO, CUIDE DE SI!
        </p>
        
        {/* Parágrafos Justificados e Ajustados */}
        <p className="text-xs md:text-lg text-justify mx-auto mt-4">
          Cansado de treinar sozinho e não atingir os resultados desejados? No MIND, todos os treinos são personalizados e realizados 
          sob a orientação e acompanhamento de um Personal Trainer.
        </p>

        {/* Botão Contacte-nos */}
        <button
          onClick={() => navigate("/contact")}
          className="mt-4 px-6 py-2 bg-[#012326] text-white rounded-lg hover:bg-[#01494d] transition-colors duration-300"
        >
          Contacte-nos!
        </button>
      </div>
    </div>
  );
}

export default Slider;
