import React from "react";
import Slider from "../components/Slider";


function Home() {
  return (
    <div className="w-full min-h-screen bg-[#f9f4ee]">
      {/* Slogan */}
      <div className="py-8 text-center">
        <h1
          style={{ color: "#012326", fontFamily: "Times New Roman, serif" }}
          className="text-sm md:text-4xl md-t font-bold "
        >
          O QUE NÃO NOS DESAFIA NÃO NOS TRANSFORMA
        </h1>
      </div>
      
      {/* Slider */}
      <Slider />
    </div>
  );
}

export default Home;